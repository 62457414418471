import { useContext, useEffect, useState } from "react";
import { API_BASE } from "../../shared/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "styled-components";
import { PaymentContext } from "../../contexts/PaymentContext";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddIcon from '@mui/icons-material/Add';
import { UserCard } from "../../shared/Interfaces";
import { getCardBrandIcon } from "../../shared/Utils";
import { Skeleton } from "@mui/material";


export default function PaymentMethod() {
    const { getAccessTokenSilently } = useAuth0();
    const { setPaymentStage, setCard, paymentOption, setPaymentToast, setPaymentMethod } = useContext(PaymentContext);
    const [cards, setCards] = useState<UserCard[]>([]);
    const [selectedCard, setSelectedCard] = useState<UserCard | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleSelectedCard = (card: UserCard) => {
        if (selectedCard?.payment_id === card.payment_id) {
            setSelectedCard(null);
        } else {
            setSelectedCard(card);
        }
    }

    useEffect(() => {
        const fetchClientSecret = async () => {
            // Create a Checkout Session
            try {
                setIsLoading(true);
                const url = API_BASE + "/api/billing/payment-methods";
                const access_token = await getAccessTokenSilently();
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        "Authorization": `Auth ${access_token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setCards(data["customer_cards"]);
                    setIsLoading(false);
                } else {
                    setPaymentToast({ type: "error", message: "Erro ao recuperar métodos de pagamento" });
                }
            } catch (error) {
                setPaymentToast({ type: "error", message: "Erro ao recuperar métodos de pagamento" });
            }
        };
        fetchClientSecret();
    }, []);

    const HandleCardButton = async () => {
        if (selectedCard) {
            setCard(selectedCard);
            setPaymentStage("payment-saved-card")
        } else if (paymentOption === "single") {
            setPaymentMethod("card");
            setPaymentStage("payment");
        } else {
            setPaymentToast({ type: "error", message: "Selecione ou cadastre um cartão de crédito para prosseguir" });
        }
    }

    const HandleBoletoButton = () => {
        setPaymentMethod("boleto");
        setPaymentStage("payment");
    }

    const DeleteCard = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, payment_id: string) => {
        event.stopPropagation();

        const confirmed = window.confirm("Você tem certeza que quer remover este cartão?");
        if (!confirmed) {
            return;
        }

        try {
            const url = `${API_BASE}/api/billing/delete-payment-method?payment_id=${payment_id}`;
            const access_token = await getAccessTokenSilently();
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization": `Auth ${access_token}`,
                }
            });
            if (response.ok) {
                setCards(cards.filter(card => card.payment_id !== payment_id));
                if (selectedCard?.payment_id === payment_id) {
                    setSelectedCard(null);
                }
                setPaymentToast({ type: "success", message: "Cartão removido com sucesso" });
            } else {
                setPaymentToast({ type: "error", message: "Erro ao remover cartão" });
            }
        } catch (error) {
            setPaymentToast({ type: "error", message: "Erro ao remover cartão" });
        }
    }

    let description: string;
    switch (paymentOption) {
        case "single":
            description = "Caso deseje pagar com um cartão de crédito, cadastre um cartão para compras futuras ou prossiga sem salvar.";
            break;
        case "automatic":
            description = "Selecione um cartão para configurar a recarga automática.";
            break;
        default:
            description = "Adicione ou remova novos cartões para futuras recargas.";
    }

    return (
        <>
            <ContentContainer>
                <Desc>{description}</Desc>
                <SubTitleContainer>
                    <StyledCreditCardIcon style={{ fontSize: "30px" }} />
                    <SubTitle>Cartões Cadastrados</SubTitle>
                </SubTitleContainer>
                <CardContainer>
                    <AddCard onClick={() => setPaymentStage("add-card")}>
                        <AddIcon
                            style={{ fontSize: "30px", color: "rgb(57, 82, 179)" }}
                        />
                    </AddCard>
                    {!isLoading ? (
                        cards.map((card) => (
                            <>
                                <Card 
                                    key={card.payment_id}
                                    onClick={() => handleSelectedCard(card)}
                                    isSelected={selectedCard?.payment_id === card.payment_id}
                                >
                                    <CardBrandContainer>
                                        <CardBrandIcon src={getCardBrandIcon(card.card_brand)} alt="Card Icon"/>
                                        <DeleteButton onClick={(event) => DeleteCard(event, card.payment_id)}>
                                            Remover
                                        </DeleteButton>
                                    </CardBrandContainer>
                                    <CardNumber>**** **** **** {card.card_last4}</CardNumber>
                                    <CardExpiration>Expira em: {card.card_expiration}</CardExpiration>
                                    <BillingName>{card.billing_name}</BillingName>
                                </Card>
                            </>
                        ))) : (
                        Array.from(new Array(2)).map((_, index) => (
                            <Card key={index}>
                                <CardNumber><Skeleton variant="text" width={100} /></CardNumber>
                                <CardExpiration><Skeleton variant="text" width={100} /></CardExpiration>
                                <BillingName><Skeleton variant="text" width={100} /></BillingName>
                            </Card>
                        ))
                    )}
                </CardContainer>
            </ContentContainer>
            {paymentOption !== "cards-only" && (
                paymentOption === "single" ? (
                    <ButtonsContainer>
                        <CardButton onClick={HandleCardButton}>Pagar com cartão</CardButton>
                        <CardButton onClick={HandleBoletoButton}>Pagar com Boleto</CardButton>
                    </ButtonsContainer>
                ) : (
                    <ButtonsContainer>
                        <CardButton onClick={HandleCardButton} width="100%">Prosseguir</CardButton>
                    </ButtonsContainer>
                )
            )}
        </>
    );
}

const DeleteButton = styled.button`
    background-color: transparent;
    padding: 5px;
    border: none;
    color: red;
    font-size: 0.9rem;
    border-radius: 5px;
    font-weight: 600;
    &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

const CardBrandIcon = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background-color: rgba(0, 0, 0, 0);
`;

const CardBrandContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Desc = styled.p`
    font-size: 1.1rem;
    font-weight: 400;
    color: black;
    color: ${props => props.theme.desc_color};
    font-family: 'Roboto', sans-serif;
    text-align: center;
    flex-wrap: wrap;
    text-align: justify;
    width: 80%;
    margin: 0px;
`;

const CardButton = styled.button<{ width?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    font-size: 1.1rem;
    font-weight: 600;
    background-color: ${props => props.theme.light_blue};
    color: white;
    border: none;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: ${props => props.width || "49%"};
    &:hover {
        background-color: ${props => props.theme.light_blue_hover};
        cursor: pointer;
    }
    &:active {
        background-color: ${props => props.theme.light_blue};
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 20px 20px 20px;
`;

const Card = styled.div<{ isSelected?: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 140px;
    margin-bottom: 10px;
    background-color: ${props => props.theme.card_white};
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: ${({ isSelected, theme }) => (isSelected ? `2px solid ${theme.light_blue}` : '0px')};
    margin-right: 15px;
    &:hover {
        background-color: ${props => props.theme.card_white_hover};
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }
    &:active {
        background-color: ${props => props.theme.card_white};
    }
    user-select: none;
`;

const AddCard = styled(Card)`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120px;
`;

const CardNumber = styled.p`
    font-size: 1rem;
    margin: 0px;
`;

const CardExpiration = styled.p`
    font-size: 0.9rem;
    color: gray;
    margin: 6px 0px 0px 0px;
`;

const BillingName = styled.p`
    font-size: 0.9rem;
    color: gray;
    margin: 10px 0px 0px 0px;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    max-width: 95%;
    flex-wrap: wrap;
`;

const SubTitleContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const StyledCreditCardIcon = styled(CreditCardIcon)`
    color: ${props => props.theme.light_blue};
    margin-right: 5px;
    margin-top: 16px;
`;

const SubTitle = styled.p`
    font-size: 1.3rem;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 60%;
    max-height: 400px;
    margin: 20px 40px 40px 40px;

    &::-webkit-scrollbar {
        display: none;
    }
    overflow-y: scroll;
    scrollbar-width: none; // Firefox
`;
