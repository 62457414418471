import { useEffect, useState } from "react";
import styled from "styled-components";
import Skeleton from '@mui/material/Skeleton';
import { getCardBrandIcon, parseDate } from "../../shared/Utils";
import { API_BASE, TOASTPROPS } from "../../shared/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { toast, ToastContainer } from "react-toastify";


export interface BasicCardInfo {
    card_brand: string;
    card_expiration: string;
    card_last4: string;
}

export interface Charge {
    value: number;
    date: string;
    status: string;
    payment_method: string | BasicCardInfo;
    receipt_url: string | null;
}

export interface ChargeList {
    charges: Charge[];
}

export default function Receipts() {
    const [loading, setLoading] = useState(false);
    const [charges, setCharges] = useState<Charge[]>();
    const { getAccessTokenSilently } = useAuth0();

    const fetchCharges = async () => {
        setLoading(true);
        try {
            const access_token = await getAccessTokenSilently();
            const url = API_BASE + "/api/billing/get-charges";
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Auth ${access_token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setCharges(data["charges"]);
                setLoading(false);
            } else {
                toast.error("Erro ao recuperar cobranças", TOASTPROPS);
            }
        } catch (error) {
            toast.error("Erro ao recuperar cobranças", TOASTPROPS);
        }
    }

    const parseValue = (value: number) => {
        return value.toString().replace(".", ",");
    }

    useEffect(() => {
        fetchCharges();
    }, []);

    return (
        <TableContainer>
            <Table>
                <thead>
                    <tr>
                        <th>Valor</th>
                        <th>Data</th>
                        <th>Status</th>
                        <th>Forma de Pagamento</th>
                        <th>Recibo</th>
                    </tr>
                </thead>
                <tbody>
                    {loading && (
                        <>
                            {Array.from(new Array(12)).map((_, index) => (
                                <tr key={index}>
                                    <td><Skeleton variant="text" width={100} /></td>
                                    <td><Skeleton variant="text" width={100} /></td>
                                    <td><Skeleton variant="text" width={100} /></td>
                                    <td><Skeleton variant="text" width={200} /></td>
                                    <td><Skeleton variant="text" width={100} /></td>
                                </tr>
                            ))}
                        </>
                    )}
                    {!loading && charges?.length === 0 && (
                        <tr>
                            <td colSpan={5}>Nenhum comprovante encontrado</td>
                        </tr>
                    )}
                    {!loading && charges?.map((charge, key) => {
                        return (
                            <tr key={key}>
                                <td>R$ {parseValue(charge.value)}</td>
                                <td>{parseDate(charge.date)}</td>
                                <td>{charge.status}</td>
                                <td>
                                    {typeof charge.payment_method === 'string' ? (
                                        charge.payment_method
                                    ) : (
                                        (
                                            <CardBrandContainer>
                                                <CardBrandIcon src={getCardBrandIcon(charge.payment_method.card_brand)} alt="Card Icon"/>
                                                <CardContent>**** **** **** {charge.payment_method.card_last4}</CardContent>
                                            </CardBrandContainer>
                                        )
                                    )}
                                </td>
                                <td>
                                    {
                                        charge.receipt_url? 
                                        <a href={charge.receipt_url} target="_blank" rel="noreferrer">Ver recibo</a>
                                        :
                                        "-"
                                    }
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </TableContainer>
    );
}

const CardContent = styled.span`
    font-size: 1.1rem;
    font-weight: 400;
    padding-top: 3px;
    color: ${props => props.theme.dark_blue};
    font-family: Plus Jakarta Sans, sans-serif;
`;

const CardBrandContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
`;

const CardBrandIcon = styled.img`
    width: 40px;
    height: 40px;
    margin: 0px;
    margin-right: 10px;
    background-color: rgba(0, 0, 0, 0);
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 40px;
`;

const Table = styled.table`
    width: 100%;

    th, td {
        text-align: left;
        padding: 11px;
    }

    th {
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        position: sticky;
        top: 0;
        background: ${props => props.theme.white_background};
        z-index: 1;
        min-width: 50px; // Example width for other columns
        @media (max-width: 1300px) {
            min-width: 100px;
        }
    }

    th:last-child, td:last-child {
        padding-right: 5px;
    }

    th:nth-child(4) {
        @media (max-width: 1300px) {
            min-width: 260px;
        }
    }

    font-size: 1.1rem;
    font-weight: 400;
    color: ${props => props.theme.dark_blue};
    font-family: Plus Jakarta Sans, sans-serif;
`;
