import { styled } from "styled-components";
import logo from "../assets/maritaca_logo.png";
import LoginButton from "../components/LoginButton";

function Login(){
    return (
        <Container>
            <Icon src={logo} alt="Maritaca logo"/>
            <Title>Bem vindo à plataforma da Maritaca AI</Title>
            <SubTitle>Acesse sua conta para continuar</SubTitle>
            <LoginButton />
            <Link href="https://www.maritaca.ai/termos-e-condi%C3%A7%C3%B5es" target="_blank">Termos de Uso</Link>
        </Container>
    )
}

export default Login;


const Link = styled.a`
    font-size: 1.1rem;
    font-weight: 350; 
    color: ${props => props.theme.dark_blue};
    font-type: regular;
    font-family: Plus Jakarta Sans, sans-serif;
    line-height: 1.7rem;
    margin-top: 10px;
`;


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
`;

const Icon = styled.img`
    width: 220px;
    height: 220px;
    margin-bottom: -10px;
`

const Title = styled.h1`
    font-size: 1.8rem;
    margin: 15px 0 0 0;
    font-weight: 800; 
    text-align: center;
    color: ${props => props.theme.dark_blue}
    font-family: Plus Jakarta Sans, sans-serif;
`

const SubTitle = styled.h3`
    font-size: 1.3rem;
    font-weight: 400;
    margin: 7px 0 5px 0;
    color: ${props => props.theme.dark_blue};
    font-family: Plus Jakarta Sans, sans-serif;
`