import React, { createContext, useState, useRef } from 'react';
import { UserCard } from '../shared/Interfaces';

interface PaymentContextProps {
    paymentStage: string; // methods, add-card, payment, payment-saved-card
    setPaymentStage: (stage: string) => void;
    paymentOption: string; // automatic, cards-only, single
    setPaymentOption: React.Dispatch<React.SetStateAction<string>>;
    card: UserCard;
    setCard: React.Dispatch<React.SetStateAction<UserCard>>;
    paymentToast: { [key: string]: string };
    setPaymentToast: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    paymentMethod: string; // card, boleto
    setPaymentMethod: React.Dispatch<React.SetStateAction<string>>;
}

export const PaymentContext = createContext<PaymentContextProps>({
    paymentStage: "",
    setPaymentStage: () => { },
    paymentOption: "",
    setPaymentOption: () => { },
    card: { payment_id: "", card_brand: "", card_last4: "", card_expiration: "", billing_name: "" },
    setCard: () => { },
    paymentToast: {},
    setPaymentToast: () => { },
    paymentMethod: "",
    setPaymentMethod: () => { },
});

export const PaymentProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [paymentStage, setPaymentStage] = useState<string>('');
    const [paymentOption, setPaymentOption] = useState<string>('');
    const [card, setCard] = useState<UserCard>({ payment_id: "", card_brand: "", card_last4: "", card_expiration: "", billing_name: "" });
    const [paymentToast, setPaymentToast] = useState<{ [key: string]: string }>({});
    const [paymentMethod, setPaymentMethod] = useState<string>('card');

    return (
        <PaymentContext.Provider value={{
            paymentStage, 
            setPaymentStage,
            paymentOption, 
            setPaymentOption, 
            card, 
            setCard, 
            paymentToast, 
            setPaymentToast,
            paymentMethod,
            setPaymentMethod
        }}>
            {children}
        </PaymentContext.Provider>
    );
};
