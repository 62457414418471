import { styled } from "styled-components";
import UserMenu from "./UserMenu";


interface UpperMenuProps {
    title: string;     
}

function UpperMenu({title}: UpperMenuProps) {
  
  return (
    <Container>
        <Title>{title}</Title>
        <UserMenu />
    </Container>
  );
}

export default UpperMenu;

export const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 600; 
    color: ${props => props.theme.dark_blue}
    font-type: bold;
    font-family: Plus Jakarta Sans, sans-serif;
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;