import GenericCardIcon from '../assets/generic_card.svg'
import VisaCardIcon from '../assets/visa.svg'
import MasterCardIcon from '../assets/mastercard.svg'


export const parseDate = (dateString: string) => {
    const [date, _] = dateString.split(" ");
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
};

export const getCardBrandIcon = (brand: string) => {
    brand = brand.toLowerCase();
    switch (brand) {
        case "visa":
            return VisaCardIcon;
        case "mastercard":
            return MasterCardIcon;
        default:
            return GenericCardIcon;
    }
}