import React, { createContext, useState } from 'react';

interface ToastProps {
    type: "error" | "success" | "info";
    message: string;
    id: string;
}

interface SideMenuContextProps {
    openHelp: boolean;
    setOpenHelp: React.Dispatch<React.SetStateAction<boolean>>;
    setToastMessage: React.Dispatch<React.SetStateAction<ToastProps | undefined>>;
    toastMessage: ToastProps | undefined;
}

export const SideMenuContext = createContext<SideMenuContextProps>({
    openHelp: false,
    setOpenHelp: () => {},
    setToastMessage: () => {},
    toastMessage: undefined,
});

export const SideMenuProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [openHelp, setOpenHelp] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<ToastProps | undefined>(undefined);

    return (
        <SideMenuContext.Provider value={{ openHelp, setOpenHelp, toastMessage, setToastMessage}}>
            {children}
        </SideMenuContext.Provider>
    );
}