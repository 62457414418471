import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "styled-components";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return <StyledButton onClick={() => loginWithRedirect()}>Entrar</StyledButton>;
};

export default LoginButton;


const StyledButton = styled.button`
    background-color: ${props => props.theme.dark_blue};
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: 10px;
    padding: 8px 140px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        background-color: ${props => props.theme.dark_blue_hover};
    }
`