import { useContext, useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styled from 'styled-components';
import { useAuth0 } from "@auth0/auth0-react";
import { API_BASE, MONTHS_MAP, TOASTPROPS } from '../shared/Constants';
import { PlanInfo, UsageHistory, UsageInfo, UsageSummary } from '../shared/Interfaces';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { MenuItem, Skeleton } from '@mui/material';
import { ModelUsageBody } from '../shared/Interfaces';
import PaymentModal from './Payment/PaymentModal';
import { PaymentContext } from '../contexts/PaymentContext';
import { toast, ToastContainer } from 'react-toastify';
import Receipts from './Payment/Receipts';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function BillingDashboard() {
    const {getAccessTokenSilently} = useAuth0();
    const [plan, setPlan] = useState<PlanInfo>();
    const [UsageInfoData, setUsageInfoData] = useState<UsageInfo>();
    const [selectedModel, setSelectedModel] = useState<string>("Todos");
    const [usageHistory, setUsageHistory] = useState<UsageHistory>();
    const [dashboardData, setDashboardData] = useState<ChartData<"bar">>();

    const yearOptions = Array.from({length: new Date().getFullYear() - 2023 + 1}, (_, i) => 2023 + i);
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    
    const [plotSubject, setPlotSubject] = useState<string>("cost");
    
    const [loading, setLoading] = useState<boolean>(false);
    
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const [selectedMonth, setSelectedMonth] = useState<string>(MONTHS_MAP[currentMonth]);

    const { paymentStage, setPaymentStage } = useContext(PaymentContext);

    const getOptions = (subject: string) => {
        const options: any = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        font: {
                            size: 15,
                        }
                    }
                },
                title: {
                    display: true,
                    text: subject === "requests" ? `Requisições Diárias - ${selectedModel} - ${selectedMonth} - ${selectedYear}` : `Gastos Diários (R$) - ${selectedModel} - ${selectedMonth} - ${selectedYear}`,
                    font: {
                        size: 20,
                    },
                    padding: {
                        top: 20,
                        bottom: 10,
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function(tooltipItem: any) {
                            let value = tooltipItem.raw; // Get the value for the hovered item
                            return parseFloat(value.toFixed(4)).toString().replace(".", ","); // Format value with up to 4 decimal places
                        }
                    }
                }
            },
            scales: {
                y: {
                    title: {
                        font: {
                            size: 20,
                        }
                    },
                    ticks: {
                        font: {
                            size: 15,
                        },
                        callback: function(value: number) {
                            // Format the number with a comma instead of a period and limit to up to 4 decimal places
                            return parseFloat(value.toFixed(4)).toString().replace(".", ",");
                        }
                    }
                },
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 10,
                        font: {
                            size: 15,
                        }
                    }
                },
            }
        };
        return options;
    };

    const findMonthIndex = (value: string) => {
        for (const [key, month] of Object.entries(MONTHS_MAP)) {
            if (month === value) {
                return parseInt(key).toString().padStart(2, '0');
            }
        }
        throw new Error("Month not found");
    }

    const fetchPlanStatus = async (accessToken: string) => {
        try {
            const url = API_BASE + "/api/billing/status";

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Auth ${accessToken}`,
                }
            });

            if (response.ok){
                const data = await response.json();
                setPlan(data);
            }
        } catch (error) {
            toast.error("Erro ao recuperar status do plano.", TOASTPROPS);
        }
    };

    useEffect(() => {
        const getUsageHistory = async (accessToken: string) => {
            const url = API_BASE + "/api/billing/usage_history";
            const month = findMonthIndex(selectedMonth);
            const lastDay = new Date(selectedYear, Number(month), 0).getDate();
            const body: ModelUsageBody = {
                startDate: `${selectedYear}-${month}-01`,
                endDate: `${selectedYear}-${month}-${lastDay}`,
            };

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Auth ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                });
                if (response.ok){
                    let data = await response.json();
                    // change the "all" key to "todos"
                    data["Todos"] = data["all"];
                    delete data["all"];
                    setUsageInfoData(data);

                    // check if selected model is in data keys
                    const data_keys = Object.keys(data);

                    let model_name = selectedModel;

                    if (!data_keys.includes(model_name)){
                        model_name= data_keys[0];
                    }

                    const model_data = data[model_name];

                    setUsageHistory(model_data);

                    const labels = model_data.daily_usage.map((d: UsageSummary) => d.short_date);
                    
                    const datasets = [
                        {
                            label: model_name,
                            data: model_data.daily_usage.map((d: UsageSummary) => d.total_requests),
                        }
                    ];
                    setDashboardData({labels, datasets});
                } else {
                    toast.error("Erro ao recuperar histórico de uso.", TOASTPROPS);
                }
            } catch (error) {
                toast.error("Erro ao recuperar histórico de uso.", TOASTPROPS);
            }
        }

        const fetchStatus = async () => {
            setLoading(true);
            const accessToken = await getAccessTokenSilently();
            await fetchPlanStatus(accessToken);
            await getUsageHistory(accessToken);
            setLoading(false);
        };
        fetchStatus();
    }, [selectedMonth, selectedYear]);

    useEffect(() => {
        const fetchStatus = async () => {
            const accessToken = await getAccessTokenSilently();
            await fetchPlanStatus(accessToken);
        }
        if (paymentStage === "refresh"){
            fetchStatus();
            setPaymentStage("");
        }
    }, [paymentStage]);

    useEffect(() => {
        if (usageHistory){
            const labels = usageHistory.daily_usage.map((d: UsageSummary) => d.short_date);

            if (plotSubject === "requests"){
            const datasets = [
            {
                label: 'Requisições',
                data: usageHistory.daily_usage.map((d:UsageSummary)=>d.total_requests),
                backgroundColor: 'rgb(54, 162, 235)',
            }
            ];
            setDashboardData({labels,datasets});

            } else {
                const datasets = [
                    {
                        label: 'Gastos',
                        data: usageHistory.daily_usage.map((d:UsageSummary)=>d.total_cost),
                        backgroundColor: 'rgb(182, 29, 62)',
                    }
                ];
                setDashboardData({labels,datasets});
            }
        }
    }, [usageHistory, plotSubject]);
    
    const formated_credits_decimals = plan?.credits !== undefined ? plan?.credits.toFixed(2) : "0,00";
    const formated_cost_decimals = usageHistory?.total_cost ? usageHistory.total_cost.toFixed(2) : "0,00";
    const formated_credits = formated_credits_decimals.replace(".", ",")
    const formated_cost = formated_cost_decimals.replace(".", ",")

    const onPlotSubjectChange = (e: SelectChangeEvent) => {
        setPlotSubject(e.target.value);
    }
      
    const onModelChange = (e: SelectChangeEvent) => {
        let model_name= e.target.value;
        if (UsageInfoData){
            let data_keys= Object.keys(UsageInfoData);
            if (data_keys.includes(model_name)){
                setUsageHistory(UsageInfoData[model_name]);
                setSelectedModel(model_name);
            }
        }
    }

    const options = getOptions(plotSubject);
    
    return (
        <Container>
            <RowContainer>
                <LeftInfoArea>
                    <ChartContainer>
                        <SummaryInfoArea>               
                            <SummaryInfoItem>
                                <SummaryInfoItemLabel>Gráfico</SummaryInfoItemLabel>
                                <Select
                                    value={plotSubject}
                                    onChange={onPlotSubjectChange}
                                    sx={{
                                        width: 150,
                                        height: 33,
                                        fontFamily: "Plus Jakarta Sans, sans-serif",
                                        borderRadius: "10px",
                                        fontSize: "1rem",
                                    }}
                                >
                                    <MenuItem value="requests">Requisições</MenuItem>
                                    <MenuItem value="cost">Gastos</MenuItem>
                                </Select>

                            </SummaryInfoItem>
                            <SummaryInfoItem>
                                <SummaryInfoItemLabel>Modelo</SummaryInfoItemLabel>
                                <Select
                                    value={selectedModel}
                                    onChange={onModelChange}
                                    sx={{
                                        width: 200,
                                        height: 33,
                                        fontFamily: "Plus Jakarta Sans, sans-serif",
                                        borderRadius: "10px",
                                        fontSize: "1rem",
                                    }}
                                >
                                    {UsageInfoData && Object.keys(UsageInfoData).map((model_name)=>{
                                        return <MenuItem value={model_name}>{model_name}</MenuItem>
                                    })}
                                </Select>
                            </SummaryInfoItem>
                            <SummaryInfoItem>
                                <SummaryInfoItemLabel>Mês</SummaryInfoItemLabel>
                                <Select
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    sx={{
                                        width: 150,
                                        height: 33,
                                        fontFamily: "Plus Jakarta Sans, sans-serif",
                                        borderRadius: "10px",
                                        fontSize: "1rem",
                                    }}
                                >
                                    {
                                        MONTHS_MAP && Object.keys(MONTHS_MAP).map((month) => {
                                            return <MenuItem value={MONTHS_MAP[parseInt(month)]}>{MONTHS_MAP[parseInt(month)]}</MenuItem>
                                        }
                                    )}
                                </Select>
                            </SummaryInfoItem>
                            <SummaryInfoItem>
                                <SummaryInfoItemLabel>Ano</SummaryInfoItemLabel>
                                <Select
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(parseInt(e.target.value as string))}
                                    sx={{
                                        width: 100,
                                        height: 33,
                                        fontFamily: "Plus Jakarta Sans, sans-serif",
                                        borderRadius: "10px",
                                        fontSize: "1rem",
                                    }}
                                >
                                    {
                                        yearOptions && yearOptions.map((year) => {
                                            return <MenuItem value={year}>{year}</MenuItem>
                                        }
                                    )}
                                </Select>
                            </SummaryInfoItem>
                            {/* <SummaryInfoItem>
                                <ExportButton>Exportar</ExportButton>
                            </SummaryInfoItem> */}
                        </SummaryInfoArea>
                        {!loading && dashboardData && <Bar options={options} data={dashboardData} />}
                        {loading && <Skeleton variant="rectangular" animation="wave" height={550} style={{marginTop: "50px", opacity: "0.3"} }/>}
                    </ChartContainer>
                    <ReceiptsContainer>
                        <TitleLabel>Comprovantes</TitleLabel>
                        <Desc>Recibos de recargas realizadas nos últimos 12 meses.</Desc>
                        <Receipts/>
                    </ReceiptsContainer>
                </LeftInfoArea>
                <RightInfoArea>
                    <SummaryRightInfo>
                        <SummaryInfoItemLabel>Saldo Disponível:</SummaryInfoItemLabel>
                        {loading ? <Skeleton width={100} height={20} /> : <SummaryInfoItemValue>R$ {formated_credits}</SummaryInfoItemValue>}
                    </SummaryRightInfo>
                    <SummaryRightInfo>
                        <SummaryInfoItemLabel>Gastos do Mês:</SummaryInfoItemLabel>
                        {loading ? <Skeleton width={100} height={20} /> : <SummaryInfoItemValue><span>R$ {formated_cost}</span></SummaryInfoItemValue>}
                    </SummaryRightInfo>
                    <SummaryRightInfo>
                        <SummaryInfoItemLabel>Requisições do Mês:</SummaryInfoItemLabel>
                        {loading ? <Skeleton width={100} height={20} /> : <SummaryInfoItemValue>{usageHistory?.total_requests}</SummaryInfoItemValue>}
                    </SummaryRightInfo>
                </RightInfoArea>
            </RowContainer>
            {paymentStage !== "" && <PaymentModal/>}
            <ToastContainer />
        </Container>
    );
}

const ReceiptsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 80px;

    @media (max-width: 1300px) {
        padding-left: 5px;
        padding-top: 320px;
    }
`

const ChartContainer = styled.div`
    height: 600px;
`

const Desc = styled.p`
    font-size: 1.2rem;
    font-family: Plus Jakarta Sans, sans-serif;
    color: black;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 3px;
`

const TitleLabel = styled.h1`
    font-size: 2.2rem;
    font-family: Plus Jakarta Sans, sans-serif;
    color: black;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;

    @media (max-width: 1300px) {
        margin-top: 250px;
    }
`

const StyledCircularProgress = styled(CircularProgress)`
    margin-top: 20px;
`

const ExportButton = styled.button`
    background-color: ${(props) => props.theme.dark_blue};
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px 35px;
    margin-top: 25px;
    font-size: 1.1rem;
    font-family: Plus Jakarta Sans, sans-serif;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.dark_blue_hover};
    }

    &:active {
        background-color: ${(props) => props.theme.dark_blue};
    }

    @media (max-width: 1300px) {
        margin-top: 5px;
    }
`

const RightInfoArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    padding-top: 80px;
    padding-left: 80px;

    @media (max-width: 1300px) {
        padding-left: 5px;
        padding-top: 340px;
        width: 90%;
    }
`

const LeftInfoArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 1200px;
    height: 600px;

    @media (max-width: 1300px) {
        width: 100%;
        height: 600px;
    }
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 100%;
    height: 100%;

    @media (max-width: 1300px) {
        flex-direction: column;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.white_background};
    width: 100%;
    height: 100%;
`;

const SummaryInfoArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    @media (max-width: 1300px) {
        flex-direction: column;
        align-items: start;
    }
`

const SummaryInfoItem = styled.div`
    display: flex;
    align-items: start;
    padding-right: 20px;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 1300px) {
        padding-right: 0;
        margin-bottom: 15px;
    }
`

const SummaryInfoItemLabel = styled.span`
    font-weight: 700;
    margin-bottom: 6px;
    margin-left: 2px;
    font-size: 1.1rem;
    font-family: Plus Jakarta Sans, sans-serif;

    @media (max-width: 1300px) {
        margin-left: 4px;
    }
`

const SummaryInfoItemValue = styled.span`
    font-size: 1rem;
    font-family: Plus Jakarta Sans, sans-serif;
`

const SummaryRightInfo = styled(SummaryInfoItem)`
    font-size: 1rem;
    padding-bottom: 20px;

    @media (max-width: 1300px) {
        padding-bottom: 10px;
    }
`