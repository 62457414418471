import { useState, useEffect, useContext } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { API_BASE } from "../../shared/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "styled-components";
import { PaymentContext } from "../../contexts/PaymentContext";


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

export interface CheckoutProps {
    setup: boolean;
}

export default function Checkout({setup}: CheckoutProps) {
    const {getAccessTokenSilently} = useAuth0();
    const [sessionID, setSessionID] = useState<string>("");
    const [completed, setCompleted] = useState<boolean>(false);
    const { setPaymentStage, setPaymentToast, paymentOption, paymentMethod } = useContext(PaymentContext);

    const getStripeStatus = async (sessionID: string) => {
        const url = API_BASE + "/api/billing/stripe-status";
        const access_token = await getAccessTokenSilently();
        const params = new URLSearchParams({
            session_id: sessionID
        }).toString();
        const response = await fetch(url + "?" + params, {
            method: "GET",
            headers: {
                "Authorization": `Auth ${access_token}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            return data.status as string;
        } else {
            setPaymentToast({type: "error", message: "Erro ao verificar status do pagamento. Por favor contate o suporte."});
            return "";
        }
    };

    
    const fetchClientSecret = async () => {
        // Create a Checkout Session
        try {
            const url = API_BASE + "/api/billing/checkout";
            const access_token = await getAccessTokenSilently();
            const paramsObj: { [key: string]: string } = {
                embedded: "true",
                save_card: setup.toString()
            };
            
            if (paymentMethod !== "") {
                paramsObj.payment_method = paymentMethod;
            }
            
            const params = new URLSearchParams(paramsObj).toString();
            
            const response = await fetch(url + "?" + params, {
                method: "GET",
                headers: {
                    "Authorization": `Auth ${access_token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setSessionID(data.session_id as string);
                return data.secret as string;
            } else {
                setPaymentToast({
                    type: "error",
                    message:
                        "Não foi possível cobrar em seu cartão, tente (re)cadastrá-lo ou usar outro cartão.",
                });
                setPaymentStage("payment");
                return "";
            }
        } catch (error) {
            setPaymentToast({
                type: "error",
                message: "Erro durante o processamento. Por favor, tente novamente mais tarde.",
            });
            return "";
        }
    };

    useEffect(() => {
        const handleCheckoutStatus = async () => {
            if (sessionID !== "" && completed) {
                const status = await getStripeStatus(sessionID);
                if (status === "complete" && setup==false) {
                    setPaymentToast({type: "success", message: "Pagamento efetuado com sucesso!"});
                } else if (status === "complete" && setup==true) {
                    if (paymentOption === "single"){
                        setPaymentToast({type: "success", message: "Cartão adicionado com sucesso!"});
                        setPaymentStage("payment");
                    }
                    else {
                        setPaymentStage("methods");
                    }
                }
                else {
                    setPaymentToast({type: "error", message: "Erro ao efetuar pagamento. Por favor contate o suporte."});
                }
            }
            setCompleted(false);
        };
        handleCheckoutStatus();
    }, [sessionID, completed]);

    return (
        <>
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{
                    fetchClientSecret: fetchClientSecret,
                    onComplete: () => {
                        setCompleted(true);
                    }
                }}
            >
                <StyledEmbeddedCheckout/>
            </EmbeddedCheckoutProvider>
        </>
    );
}

const StyledEmbeddedCheckout = styled(EmbeddedCheckout)`
    overflow-y: auto;
    height: 100%;
    width: 400px;
    overflow-y: auto;
    border-radius: 15px;
`;