import { styled } from "styled-components";
import logo from "../assets/maritaca_logo.png";

export default function NotFound() {
    return (
        <Container>
            <Icon src={logo} alt="Maritaca logo"/>
            <Title>404</Title>
            <SubContainer>
                <Subtitle>O endereço acessado não existe</Subtitle>
                <a href="/">Voltar para a pagina inicial</a>
            </SubContainer>
        </Container>
    );
};

const SubContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Icon = styled.img`
    width: 220px;
    height: 220px;
    margin-bottom: -10px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const Title = styled.h1`
    font-size: 5rem;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
    color: ${props => props.theme.dark_blue};
    font-family: Plus Jakarta Sans, sans-serif;
`;

const Subtitle = styled.h3`
    font-size: 2rem;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 10px;
    color: ${props => props.theme.desc_color};
    font-family: Plus Jakarta Sans, sans-serif;
`;

