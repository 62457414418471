import { useContext, useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { API_BASE } from '../shared/Constants';
import CloseIcon from '@mui/icons-material/Close';
import { SideMenuContext } from '../contexts/SideMenuContext';
import { v4 as uuidv4 } from 'uuid';


export interface HelpBody {
    name: string;
    contact_info: string;
    date: string;
    subject: string;
    message: string;
}

export default function HelpForm() {
    const nameInputRef = useRef<HTMLInputElement>(null);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const messageInputRef = useRef<HTMLTextAreaElement>(null);
    const subjectInputRef = useRef<HTMLSelectElement>(null);
    const [subjects, setSubjects] = useState<string[]>([]);

    const { user, getAccessTokenSilently } = useAuth0();
    // We use setToastMessage to show a toast that is not rendered in this component
    // making the toast message "global" to the application and allowing closing the help form
    // when the user successfully sends a help request
    const { setOpenHelp, setToastMessage } = useContext(SideMenuContext);

    useEffect(() => {
        const fetchSubjects = async () => {
            const url = API_BASE + "/api/subjects";
            try {
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    setSubjects(data["subjects"]);
                } else {
                    setToastMessage({ type: "error", message: "Erro ao carregar assuntos de ajuda", id: uuidv4() });
                }
            } catch (error) {
                setToastMessage({ type: "error", message: "Erro ao carregar assuntos de ajuda", id: uuidv4() });
            }
        }
        fetchSubjects();
    }, []);

    const sendHelpRequest = async () => {
        const name = nameInputRef.current?.value;
        const email = emailInputRef.current?.value;
        const message = messageInputRef.current?.value;
        const subject = subjectInputRef.current?.value;

        if (!name || !email || !message || !subject) {
            setToastMessage({type: "error", message: "Preencha todos os campos", id: uuidv4()});
            return;
        }

        // check if email is valid
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;;
        if (!emailRegex.test(email)) {
            setToastMessage({type: "error", message: "Email inválido", id: uuidv4()});
            return;
        }

        const url = API_BASE + "/api/help";
        const helpBody: HelpBody = {
            name: name,
            contact_info: email,
            date: new Date().toISOString(),
            subject: subject,
            message: message
        };
        const token = await getAccessTokenSilently();
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Auth " + token,
                },
                body: JSON.stringify(helpBody)
            });
            if (response.ok) {
                setToastMessage({type: "success", message: "Pedido de ajuda enviado com sucesso", id: uuidv4()});
                setOpenHelp(false);
            } else {
                setToastMessage({type: "error", message: "Erro ao enviar pedido de ajuda", id: uuidv4()});
            }
        } catch (error) {
            setToastMessage({type: "error", message: "Ocorreu um erro no formulário de ajuda.", id: uuidv4()});
        }
    }

    useEffect(() => {
        let displayedName = user?.given_name && user?.family_name
            ? `${user.given_name} ${user.family_name}`
            : user?.name ?? "";
        nameInputRef.current!.value = displayedName;
        emailInputRef.current!.value = user?.email ?? "";
    }
    , []);

    return (
        <>
            <Form >
                <Span>
                    <Title>Suporte</Title>
                    <CloseButton type="button" onClick={() => setOpenHelp(false)}>
                        <CloseIcon/>
                    </CloseButton>
                </Span>
                <Description>Preencha este formulário para que possamos entrar em contato através do email informado.</Description>
                <Label>Nome*</Label>
                <Input ref={nameInputRef} type="text" required maxLength={70}/>
                <Label>Email*</Label>
                <Input ref={emailInputRef} type="email" required maxLength={100}/>
                <Label>Assunto*</Label>
                <Select ref={subjectInputRef}>
                    {Array.isArray(subjects) && subjects.map((subject, index) => (
                        <option key={index}>{subject}</option>
                    ))}
                </Select>
                <Label>Mensagem*</Label>
                <Message required ref={messageInputRef} maxLength={1000}/>
                <Button type="button" onClick={sendHelpRequest}>Enviar</Button>
            </Form>
        </>
    );
}

const Select = styled.select`
    border: 1px solid ${props => props.theme.input_border};
    background-color: white;
    &:focus {
        border-color: ${props => props.theme.input_outline};
        outline: none;
    }
    border-radius: 10px;
    padding: 5px;
    margin-top: 8px;
    margin-bottom: 20px;
    font-size: 1.3rem;
`;

const CloseButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

const Span = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Button = styled.button`
    background-color: ${props => props.theme.green};
    color: white;
    border: none;
    border-radius: 10px;
    padding: 8px 30px;
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 1.3rem;
    font-weight: 700;
    font-family: Plus Jakarta Sans, sans-serif;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.green_hover};
    }

    &:active {
        background-color: ${props => props.theme.green};
    }
`

const Title = styled.h1`
    font-size: 1.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    margin-top: 10px 0 0 0;
`;

const Description = styled.p`
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin: 0 0 10px 0;
`;

const Input = styled.input`
    border: 1px solid ${props => props.theme.input_border};
    &:focus {
        border-color: ${props => props.theme.input_outline};
        outline: none;
    }
    border-radius: 10px;
    padding: 5px;
    margin-top: 8px;
    margin-bottom: 20px;
    font-size: 1.3rem;
`;

const Message = styled.textarea`
    border: 1px solid ${props => props.theme.input_border};
    &:focus {
        border-color: ${props => props.theme.input_outline};
        outline: none;
    }
    border-radius: 10px;
    padding: 5px;
    margin-top: 8px;
    margin-bottom: 20px;
    font-size: 1.3rem;
    resize: none;
    min-height: 200px;
    font-family: 'Roboto', sans-serif;
`;

const Label = styled.p`
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    margin: 0px;
`;

const Form = styled.form`
    display: flex;
    width: 40%;
    min-width: 400px;
    max-width: 700px;
    background-color: white;
    border-radius: 15px;
    padding: 0px 20px 0px 20px;
    flex-direction: column;
`;