import styled from "styled-components";
import styledMUI from "@mui/material/styles/styled";
import logo from "../../assets/maritaca_logo.png";
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import AccountBalanceWalletSharpIcon from '@mui/icons-material/AccountBalanceWalletSharp';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SmartToyRoundedIcon from '@mui/icons-material/SmartToyRounded';
import MenuIcon from '@mui/icons-material/Menu';
import Modal from '@mui/material/Modal';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import ChatIcon from '@mui/icons-material/Chat';
import PaidIcon from '@mui/icons-material/Paid';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { TitleContext } from "../../contexts/TitleContext";
import { SideMenuContext } from "../../contexts/SideMenuContext";
import HelpForm from "../HelpForm";
import { ToastContainer, toast } from "react-toastify";
import { TOASTPROPS } from "../../shared/Constants";

function SideMenu() {
    const { title, setTitle } = useContext(TitleContext);
    const { openHelp, setOpenHelp, toastMessage } = useContext(SideMenuContext);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleMenu = () => {
        setIsCollapsed(!isCollapsed);
    };

    useEffect(() => {
        const showToast = () => {
            if (toastMessage) {
                switch (toastMessage.type) {
                    case "error":
                        toast.error(toastMessage.message, TOASTPROPS);
                        break;
                    case "success":
                        toast.success(toastMessage.message, TOASTPROPS);
                        break;
                    case "info":
                        toast.info(toastMessage.message, TOASTPROPS);
                        break;
                }
            }
        }
        showToast();
    }, [toastMessage]);

    useEffect(() => {
        const handlePageChange = () => {
            if (window.location.pathname === "/chaves-de-api") {
                setTitle("Chaves de API");
            } else if (window.location.pathname === "/recarga") {
                setTitle("Recarga");
            } else if (window.location.pathname === "/modelos") {
                setTitle("Modelos");
            } else if (window.location.pathname === "/meus-gastos") {
                setTitle("Meus Gastos");
            } else if (window.location.pathname === "/") {
                setTitle("Meus Gastos");
            }
        };

        handlePageChange();

        const handlePopstate = () => {
            handlePageChange();
        };

        window.addEventListener("popstate", handlePopstate);

        return () => {
            window.removeEventListener("popstate", handlePopstate);
        };
    }, []);

    return (
        <>
            {isCollapsed && <StyledMenuIcon onClick={toggleMenu} />}
            <Container isCollapsed={isCollapsed}>
                <StyledKeyboardArrowDownIcon onClick={toggleMenu} />
                <UpperSection>
                    <LogoContainer>
                        <Logo src={logo} alt="Maritaca logo" />
                        <Title>Maritaca AI</Title>
                    </LogoContainer>
                    <HorizontalLine />
                    <StyledNavLink to="/meus-gastos" active={title === "Meus Gastos"} onClick={() => setTitle("Meus Gastos")}>
                        <ButtonContainer>
                            <StyledAccountBalanceIcon />
                            <ButtonText>Meus Gastos</ButtonText>
                        </ButtonContainer>
                    </StyledNavLink>
                    <StyledNavLink to="/chaves-de-api" active={title === "Chaves de API"} onClick={() => setTitle("Chaves de API")}>
                        <ButtonContainer>
                            <StyledKeyOutlinedIcon />
                            <ButtonText>Chaves de API</ButtonText>
                        </ButtonContainer>
                    </StyledNavLink>
                    <StyledNavLink to="/modelos" active={title === "Modelos"} onClick={() => setTitle("Modelos")}>
                        <ButtonContainer>
                            <StyledSmartToyIcon />
                            <ButtonText>Modelos</ButtonText>
                        </ButtonContainer>
                    </StyledNavLink>
                    {/* <StyledNavLink to="/playground" active={title === "Playground"} onClick={() => setTitle("Playground")}>
                        <ButtonContainer>
                            <StyledChatIcon />
                            <ButtonText>Playground</ButtonText>
                        </ButtonContainer>
                    </StyledNavLink> */}
                    <StyledNavLink to="/recarga" active={title === "Recarga"} onClick={() => setTitle("Recarga")}>
                        <ButtonContainer>
                            <StyledPaidIcon />
                            <ButtonText>Recarga</ButtonText>
                        </ButtonContainer>
                    </StyledNavLink>
                </UpperSection>
                <LowerSection>
                    <ContainerDoubleButton>
                        <Button onClick={() => setOpenHelp(true)}>
                            <LowerButtonContainer>
                                <StyledInfoIcon />
                                <ButtonText>Ajuda</ButtonText>
                            </LowerButtonContainer>
                        </Button>
                        <Button onClick={() => window.open("https://docs.maritaca.ai", "_blank")}>
                            <LowerButtonContainer>
                                <StyledDocIcon />
                                <ButtonText>Docs</ButtonText>
                            </LowerButtonContainer>
                        </Button>
                    </ContainerDoubleButton>
                </LowerSection>
            </Container>
            {openHelp &&
                <HelpModal
                    open={openHelp}
                    onClose={() => setOpenHelp(false)}
                    BackdropProps={{ onClick: (event) => event.stopPropagation() }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <HelpForm />
                </HelpModal>
            }
            <ToastContainer />
        </>
    );
}

export default SideMenu;


const StyledNavLink = styled(NavLink)<{ active?: boolean }>`
    background-color: white;
    border: none;
    outline: none;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    color: ${props => props.active ? props.theme.light_blue : props.theme.menu_inactive};
    transition: 0.3s;
    justify-content: center;
    text-decoration: none;

    &:hover {
        background-color: ${props => props.theme.card_white};
    }

    &:active {
        background-color: ${props => props.theme.card_white_hover};
    }
`;


const StyledKeyboardArrowDownIcon = styledMUI(ArrowBackIosIcon)`
    color: ${props => props.theme.variables.dark_blue};
    font-size: 20px;
    padding-left: 10px;
    padding-right: 5px;
    margin: 10px 0 0 auto;
    cursor: pointer;
`;

const StyledDocIcon = styledMUI(AutoStoriesIcon)`
    font-size: 21px;
    margin-right: 10px;
`;

const StyledInfoIcon = styledMUI(InfoRoundedIcon)`
    font-size: 21px;
    margin-right: 10px;
`;

const StyledSmartToyIcon = styledMUI(SmartToyRoundedIcon)`
    font-size: 21px;
    margin-right: 10px;
`;

const StyledPaidIcon = styledMUI(PaidIcon)`
    font-size: 21px;
    margin-right: 10px;
`;

const StyledChatIcon = styledMUI(ChatIcon)`
    font-size: 21px;
    margin-right: 10px;
`;

const StyledRoundedIcon = styledMUI(SettingsRoundedIcon)`
    font-size: 21px;
    margin-right: 10px;
    color: ${props => props.theme.variables.dark_blue};
`;

const StyledKeyOutlinedIcon = styledMUI(KeyOutlinedIcon)`
    font-size: 21px;
    margin-right: 10px;
`;

const StyledAccountBalanceIcon = styledMUI(AccountBalanceWalletSharpIcon)`
    font-size: 21px;
    margin-right: 10px;
`;

const StyledMenuIcon = styledMUI(MenuIcon)`
    position: fixed;
    top: 5px;
    left: 5px;
    color: ${props => props.theme.variables.dark_blue};
    font-size: 35px;
    cursor: pointer;
    z-index: 1100; // Ensure it's higher than other elements
    display: block;
`;


const HelpModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LowerSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: auto;
    margin-bottom: 12px;
`;

const UpperSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const Container = styled.div<{ isCollapsed: boolean }>`
    min-height: 100vh;
    min-width: ${props => props.isCollapsed ? '0' : '270px'};
    width: ${props => props.isCollapsed ? '0' : '270px'};
    background-color: white;
    overflow-x: hidden;
    transition: 0.4s;
    display: flex;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    align-items: center;
    transform: ${props => props.isCollapsed ? 'translateX(-100%)' : 'translateX(0)'};

    @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 35px;
    padding-top: 15px;
    padding-bottom: 15px;
`;

const LowerButtonContainer = styled(ButtonContainer)`
    color: ${props => props.theme.dark_blue};
`;

const Logo = styled.img`
    width: 50px;
    height: 50px;
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 0px;
    margin-left: -10px;
`;

const Title = styled.h2`
    font-size: 1.5rem;
    font-weight: 800; 
    color: ${props => props.theme.dark_blue};
    font-type: bold;
    font-family: Plus Jakarta Sans, sans-serif;
`

const HorizontalLine = styled.hr`
    width: 100%;
    display: block;
    justify-content: left;
    border: 0.1px solid ${props => props.theme.card_white_hover};
    opacity: 0.8;
    margin: 20px 0 20px 0;
`;

const Button = styled.button<{ active?: boolean }>`
    background-color: white;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    width: 100%;
    padding-block: 0;
    padding-inline: 0;
    color: ${props => props.active ? props.theme.light_blue : props.theme.menu_inactive};

    transition: 0.3s;
    justify-content: space-between;

    &:hover {
        background-color: ${props => props.theme.card_white};
    }

    &:active {
        background-color: ${props => props.theme.card_white_hover};
    }
`;

const ButtonText = styled.p`
    margin: 0;
    font-size: 0.9rem;
    font-weight: 650;
    font-family: Plus Jakarta Sans, sans-serif;
`;

const ButtonTextHighlighted = styled.p`
    margin: 0;
    font-size: 0.85rem;
    font-weight: 800;
    color: ${props => props.theme.light_blue};
    margin-right: 10px;
    align-self: right;
    border-radius: 14px;
    padding: 4px 13px;
    margin-top: 10px;
    background-color: ${props => props.theme.soon_background};
    justify-self: right;
    justify-content: right;
    font-family: Plus Jakarta Sans, sans-serif;
`;

const ContainerDoubleButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;
