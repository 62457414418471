import { green, red } from "@mui/material/colors";

const Theme = {
    dark_blue: "#1d2752",
    dark_blue_hover: "#2b3975",
    light_blue: "rgb(57, 82, 179)",
    light_blue_hover: "rgb(47, 72, 159)",
    card_white: "rgb(245, 247, 243)",
    card_white_hover: "#f0f0f0",
    input_border: "#E5E5E5",
    input_outline: "#aeb1b4",
    white_divider: "#d9d9d9",
    menu_inactive: "rgba(150, 151, 153, 0.8)", // "rgba(183, 188, 207, 0.8)"
    white_background: "#F7FAFC",
    soon_background: "rgba(149, 166, 233, 0.3)",
    gray_hover: "#5e5d5d",
    dark_gray: "#2a2b2a",
    desc_color: "#8f8f8f",
    green: "#2aac6b",
    green_hover: "#1f8c4e",
    red: "#db5329",
    red_button: "#c23e39",
    red_hover: "#9c381a",
}

export default Theme;